import { Component, OnInit, Input, AfterViewInit, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition, query, stagger, keyframes } from '@angular/animations';

import { Message } from '../../_models/message-types/message';
import { ChatItemComponent } from '../chat-item/chat-item.component';
import { MessageService } from '../../_services/message.service';
import { catchError } from 'rxjs/operators';

declare var $:any;

@Component({
  selector: 'chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss'],
  animations: [
    // This animation needed to instantiate the stagger on list items
    trigger('bubbleList', [
      /* transition('* => *', [
        query(':enter', style({ opacity: 0 }), { optional: true }),
        query(':enter', stagger('100ms', [
          animate('0.3s ease-in', keyframes([
            style({ opacity: 0, transform: 'scale(0.2)', offset: 0 }),
            style({ opacity: 1, transform: 'scale(1)', offset: 1.0 }),
          ]))
        ]), { optional: true }),
      ]), */
    ]),
  ]
  
})
export class ChatListComponent implements OnInit {

  @Input('messages')
  messages : any;

  @ViewChild('scrollDiv', { read: ElementRef }) scrollDiv: ElementRef;
  @ViewChildren(ChatItemComponent, { read: ElementRef }) chatItems: QueryList<ChatItemComponent>;
  scrollTopPosition: number = 0;

  constructor(private _msg: MessageService,) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(()=>{

      this.chatItems.changes.subscribe(elements => {
        //console.log('messsage list changed: ' + this.messages.length);
        this.scrollToBottom();
      });
    });
  }

  scrollToBottom () {
    let target;
    target = $('.chat').last();
    if (target) {
      $('html,body').animate({ scrollTop: $(target).offset().top - 110 }, 'slow', () => {
        target.focus();
        //console.log('scrolled')
      });
    }
  }

}
