import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
 
import { FieldBase } from '../../_models/form-fields/field-base';
import { DataService } from '../../_services/data.service';
import { FormSection } from 'src/app/_models/message-types/form-section';

declare var $:any;

@Component({
  selector: 'app-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss']
})
export class DynamicFormFieldComponent {

  submitted: any;
  loading: any;

  @Input() field: FieldBase<any>;
  @Input() section: FormSection<any>;
  @Input() form: FormGroup;
  @Input() form0: FormGroup;
  get isValid() { return this.form.controls[this.field.name].valid; }
  // List of boolean vars checking validators on fields
  get invalidRequired() { return this.form.controls[this.field.name].hasError('required'); }
  get invalidEmail() { return this.form.controls[this.field.name].hasError('email'); }
  get invalidNumberRange() { return this.form.controls[this.field.name].hasError('numberRange'); }

  constructor(private _data: DataService) {  
    //get submitted from the service to use for validation
      _data.submitted$.subscribe(val => {this.submitted = val;},err => {},() => {});
  }

  ngAfterViewInit() {
  }
}
