export class MessageProcessing {
    input_text: string;
    type: string;
    author: string;
    isFirst: boolean;
    pause: boolean;
    date_time: Date;

    constructor(
        input_text: string,
        author: string,
        isFirst: boolean,
        pause?: boolean,
        date_time?: Date,
    ) {
        this.input_text = input_text;
        this.type = 'processing';
        this.author = 'bot';
        this.isFirst = isFirst;
        this.pause = pause;
        this.date_time = date_time;
    }
}