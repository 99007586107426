export class Message {
    input_text: string;
    type: string;
    author: string;
    isFirst: boolean;
    value: string;
    date_time: Date;

    constructor(
        input_text: string,
        type: string, 
        author: string, 
        isFirst: boolean, 
        value?: string,
        date_time?: Date,
    ) {
        this.input_text = input_text; // cast the value of content to be the model's value of content
        this.type = type;
        this.author = author;
        this.isFirst = isFirst;
        this.value = value;
        this.date_time = date_time;
    }
}
