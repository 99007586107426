import { Injectable, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  private marker = new BehaviorSubject<any>(null); // store for Messages
  public marker$ = this.marker.asObservable(); // observble store object

  private markerCreate = new BehaviorSubject<boolean>(null); // store for Messages
  public markerCreate$ = this.markerCreate.asObservable(); // observble store object

  constructor() { }

  setMarker(data) {
    this.marker.next(data) // sets data in private messageData object
    //console.log('marker set to:')
    //console.log(this.marker['_value'])
  }
  setMarkerCreate(data) {
    this.markerCreate.next(data) // sets data in private messageData object
  }
}
