import { Injectable } from '@angular/core';

import { FieldBase }     from '../_models/form-fields/field-base';
import { TextboxQuestion }  from '../_models/form-fields/textbox';
import { DropdownQuestion } from '../_models/form-fields/dropdown';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  // TODO: get from a remote source of question metadata
  // TODO: make asynchronous
  getQuestions() {
 
    let fields: FieldBase<any>[] = [
 
      new DropdownQuestion({
        name: 'brave',
        question: 'Bravery Rating',
        options: [
          {key: 'solid',  value: 'Solid'},
          {key: 'great',  value: 'Great'},
          {key: 'good',   value: 'Good'},
          {key: 'unproven', value: 'Unproven'}
        ],
        order: 3
      }),
 
      new TextboxQuestion({
        name: 'firstName',
        question: 'First name',
        value: 'Bombasto',
        required: true,
        order: 1
      }),
 
      new TextboxQuestion({
        name: 'emailAddress',
        question: 'Email',
        type: 'email',
        order: 2
      })
    ];
 
    return fields.sort((a, b) => a.order - b.order);
  }

  constructor() { }
}
