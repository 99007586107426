export class MessageInput {
    type: string;
    author: string;
    value: string;
    question: string;
    answerOptions: Array<string>;
    date_time: Date;

    constructor(
        type: string,
        value: string,
        question: string,
        answerOptions: Array<string>,
        date_time?: Date,
    ) {
        this.type = type;
        this.author = 'bot';
        this.value = value;
        this.question = question;
        this.answerOptions = answerOptions;
        this.date_time = new Date();
    }
}