import { FieldBase } from './field-base';

export class DropdownQuestion extends FieldBase<string> {
    //answerType = 'dropdown'; // controlType
    answerOptions: { key: string, value: string }[] = [];
    //answerOptions = [];

    newArray = new Array;
    newNewArray = new Array;

    constructor(options: {} = {}) {
        super(options);
        this.answerOptions = options['answerOptions'] || [];
        //Object.keys(this.answerOptions).forEach((key) => { this.newArray.push(key, this.answerOptions[key]) });

    }
}