export class FieldBase<T> {
    // General field variables
    value: T;
    name: string; // key
    question: string; // label
    questionShort: string; // label
    answerType: string; // controlType
    type: string; // controlType
    tooltip: string;
    // Control variables
    theme: string;
    required: boolean;
    order: number;
    validations: Array<any>;
    sectionName: string;

    constructor( options: {
        value?: T,
        name?: string,
        question?: string,
        questionShort?: string,
        answerType?: string,
        type?: string,
        tooltip?: string,
        theme?: string,
        required?: boolean,
        order?: number,
        validations?: Array<any>,
        sectionName?: string
    } = {}) {
        this.value = options.value;
        this.name = options.name || '';
        this.question = options.question || '';
        this.questionShort = options.questionShort || '';
        this.answerType = options.answerType || '';
        this.type = options.type || '';
        this.tooltip = options.tooltip || '';
        this.theme = options.theme || '';
        this.required = !!options.required;
        this.order = options.order === undefined ? 1 : options.order;
        this.validations = options.validations || [];
        this.sectionName = options.sectionName || '';
    }
}