// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: 'https://commander.cavalryapps.com/car-track-login-demo-uat/bot-api/public/alpha',
  apiUrl: 'http://hearkern.api.sbsoftware.co.za/Alpha/bot-api/public/alpha',
  // apiUrl: 'http://localhost/Projects/CaptisInvestmentApp/Alpha',
  //apiUrl: 'http://362d24c8.ngrok.io/BOT_API_V2/bot-api/public/alpha',chattydemo.o2projects.co.za/Alpha/bot-api/module/Alpha/src
  settings: {
    env: 'chat', // options: modal, fullscreen, chat
    startMessage: 'creativeexpression',
    logo: '', // location of logo
  },
  models: {
    product: 'bike', // name this API uses to describe insurance product eg: bike, car, person, item 
  },
  businessRules : {
    valMinMax: {
      min : 300,
      max : 3000
    },
    amountMinMax: {
      min : 300,
      max : 3000
    }
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
