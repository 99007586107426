import { AbstractControl, ValidatorFn } from '@angular/forms';

export function ValidateNumberRange(minvalue: number, maxvalue: number): ValidatorFn {

    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== '') {
        if (control.value < minvalue || control.value > maxvalue) {
          return { 'numberRange': true };
        }
      }
      return null;
  };
}