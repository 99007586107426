import { Injectable, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Message } from '../_models/message-types/message';
import { MessageInput } from '../_models/message-types/input';
import { FieldBase } from 'src/app/_models/form-fields/field-base';
import { TextboxQuestion } from 'src/app/_models/form-fields/textbox';
import { DropdownQuestion } from 'src/app/_models/form-fields/dropdown';
import { CheckboxQuestion } from 'src/app/_models/form-fields/checkbox';
import { TextareaQuestion } from 'src/app/_models/form-fields/textarea';
import { Form } from 'src/app/_models/message-types/form';
import { FormSection } from 'src/app/_models/message-types/form-section';

import { DataService } from '../_services/data.service';
import { MessageProcessing } from '../_models/message-types/processing';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  route: string; // var to capture location
  chat_history_link: string;

  @Input('message')
  private message : Message;

  @Input('input')
  private input : MessageInput;

  @Input('messages')
  private messages = new BehaviorSubject<any>(null);
  public messages$ = this.messages.asObservable();
  messagesHolder: any[] = []; // locally actionable variable

  private chatMessages = new BehaviorSubject<any>(null);
  public chatMessages$ = this.chatMessages.asObservable();
  chatMessagesHolder: any; // locally actionable variable

  private messageData = new BehaviorSubject<any>(null);
  public messageData$ = this.messageData.asObservable();
  
  private newMessage = new BehaviorSubject<any>(null);
  public newMessage$ = this.newMessage.asObservable();

  private newForm = new BehaviorSubject<any>(null);
  public newForm$ = this.newForm.asObservable();

  private fieldArray = new BehaviorSubject<any>(null);
  public fieldArray$ = this.fieldArray.asObservable();
  fieldArrayHolder = <any>[] // locally actionable variable
  
  private sectionArray = new BehaviorSubject<any>(null);
  public sectionArray$ = this.sectionArray.asObservable();
  sectionArrayHolder = <any>[] // locally actionable variable
  
  // Help State
  private helpMessages = new BehaviorSubject<any>(null);
  public helpMessages$ = this.helpMessages.asObservable();

  private helpInitMessage = new BehaviorSubject<any>(null);
  public helpInitMessage$ = this.helpInitMessage.asObservable();
  
  // Edit State
  private editMessages = new BehaviorSubject<any>(null);
  public editMessages$ = this.editMessages.asObservable();

  // Process Variables
  private processStatus = new BehaviorSubject<any>(null);
  public processStatus$ = this.processStatus.asObservable();

  private processMessage = new BehaviorSubject<any>(null);
  public processMessage$ = this.processMessage.asObservable();

  private formDetected = new BehaviorSubject<any>(null);
  public formDetected$ = this.formDetected.asObservable();
  formDetectedHolder: any;

  private loading = new BehaviorSubject<boolean>(null);
  public loading$ = this.loading.asObservable();

  private hasOptions = new BehaviorSubject<boolean>(null);
  public hasOptions$ = this.hasOptions.asObservable();
  
  private formLoadCount = new BehaviorSubject<any>(null);
  public formLoadCount$ = this.formLoadCount.asObservable();

  constructor(
    private _data: DataService,
    private router: Router,
    private location: Location,
  ) { 
    // use location to set route
    router.events.subscribe((val) => {
      if(location.path() != ''){
        this.route = location.path();
      } else {
        this.route = '/'
      }
    });

    //this.chatMessages$.subscribe(data =>{this.chatMessagesHolder = data}) // sub to locally actionable var
    this.fieldArray$.subscribe(data =>{this.fieldArrayHolder = data}) // sub to locally actionable var
    this.sectionArray$.subscribe(data =>{this.sectionArrayHolder = data}) // sub to locally actionable var
    this.formDetected$.subscribe(data =>{this.formDetectedHolder = data}) // sub to locally actionable var
  }

  // Set Actions for Data Stores
  // method takes Messages data generated from an API call elsewhere, then stores here for sharing in Observable
  setMessages(data) {
    this.messages.next(data) // sets data in private messageData object
  }
  setChatMessages(data) {
    this.chatMessages.next(data) // sets data in private messageData object
  }
  setMessageData(data) {
    this.messageData.next(data) // sets data in private messageData object
  }
  setNewMessage(data) {
    this.newMessage.next(data) // sets data in private newMessage object
  }
  setNewForm(data) {
    this.newForm.next(data) // sets data in private newMessage object
  }
  setProcessStatus(data) {
    this.processStatus.next(data) // sets data in private processStatus object
  }
  setProcessMessage(data) {
    this.processMessage.next(data) // sets data in private processStatus object
  }
  setHelpMessages(data) {
    this.helpMessages.next(data) // sets data in private messageData object
  }
  setHelpInitMessage(data) {
    this.helpInitMessage.next(data) // sets data in private newMessage object
  }
  setEditMessages(data) {
    this.editMessages.next(data) // sets data in private messageData object
  }
  setLoading(data) {
    this.loading.next(data) // sets data in private messageData object
  }
  setHasOptions(data) {
    this.hasOptions.next(data) // sets data in private messageData object
  }
  setFieldArray(data) {
    this.fieldArray.next(data) // sets data in private messageData object
  }
  setSectionArray(data) {
    this.sectionArray.next(data) // sets data in private messageData object
  }
  setFormDetected(data) {
    this.formDetected.next(data) // sets data in private messageData object
  }
  setFormCount(data) {
    this.formLoadCount.next(data) // sets data in private messageData object
  }

  clearMessages(){
    this.messagesHolder = [];
  }

  // Message Processing Logic

  sendMessage(message, messages) {
    //console.log('sendMessage fired')
    message.author = 'user'; // set message author to user
    var link = JSON.parse(localStorage.getItem('talk_back_link'));

    // format message to send as formData object, iterating over message key-value pairs
    var formData = new FormData();
    Object.keys(message).forEach((key)=>{formData.append(key,message[key])});

    var messages1 = [];

    formData['messages'] = this.messages['_value'] // add Messages list to each message sent

    this._data.endPoint(formData,link)
      .subscribe(
        data => {
          // Set global data
          messages1 = messages;
          localStorage.setItem('talk_back_link', JSON.stringify(data.talk_back_link));
          this.setLoading(false);

          //update Messages to correct context
          if(this.route === '/chat' || this.route === '/'){
            this.setMessages(messages1); // set response to service storage object
          }
          if(this.route === '/help'){
            this.setHelpMessages(messages1); // set response to service storage object
          }
          if(this.route === '/edit'){
            this.setEditMessages(messages1); // set response to service storage object
          }

          // iterate over the response object to pick up messge types and instantiate chat bubbles
          // based on message type eg. message, input, button etc


          // TRIGGERING HELP STATE
          /* if (data.chat_response.help === true) {
            var helpInitMessage = data.contextincontext[0].input.text;  //capture message that initiated help
            this.setHelpInitMessage(helpInitMessage); // pass message to the service
            this.router.navigate(['/help']); //route user to Help screen
          } */
        },
        err => {
          console.log('Error detected:'+err.error_msg);
          this.setLoading(false);
        },
        () => {
          this.processMessages();
        }
      );

  }

  sendMessageOptions(message, messages) {
    var delay: number;
    //console.log("sendMessageOptions fired");
    if (this.processMessage['_value']) {
      delay = 5000;

      // Display processing message
      this.messagesHolder.push(
        new MessageProcessing(this.processMessage['_value'].message,'bot',true,null,new Date())
      );

      var messagesHolderObject1 = [];
      this.messages['_value'].forEach(message => { messagesHolderObject1.push(message); });
      this.messagesHolder.forEach(message => { messagesHolderObject1.push(message); });
      //console.log(messagesHolderObject1)
      this.setMessages(messagesHolderObject1);
      this.messagesHolder = [];
    } else {
      delay = 0;
    }

    // create timing delay
    this.sleep(delay).then(() => {

      //console.log('sendMessageOptions fired')
      var link = JSON.parse(localStorage.getItem('talk_back_link'));
      var messages2 = [];

      message['messages'] = this.messages['_value'] // add Messages list to each message sent

      this._data.endPoint(message, link) // use for live
      //this._data.testOptions() // use for testing
        .subscribe(
          data => {
            // Set Global data
            messages2 = messages;
            localStorage.setItem('talk_back_link', JSON.stringify(data.talk_back_link));
            this.setLoading(false);

            this.chatMessagesHolder = data.chat_response; // set response to object
            this.setChatMessages(data.chat_response) // set response to object

            //update Messages to correct context
            this.setMessageData(this.chatMessagesHolder); // set response to service storage object
            if(this.route === '/chat' || this.route === '/'){
              this.setMessages(messages2); // set response to service storage object
            }
            if(this.route === '/help'){
              this.setHelpMessages(messages2); // set response to service storage object
            }
            if(this.route === '/edit'){
              this.setEditMessages(messages2); // set response to service storage object
            }

            // iterate over the response object to pick up messge types and instantiate chat bubbles 
            // based on message type eg. message, input, button etc

            //check for handover to livechat
            if(data.chat_status_flags != undefined) {
              if (data.chat_status_flags.quote_path_status != undefined && data.chat_status_flags.quote_path_status == 'live-agent') {
                this.chat_history_link = data.chat_status_flags['chat-history-link'];
                 //route user to live chat
                this.processMessages();
                this.router.navigate(['/livechat']);
              } 
            }
 
          },
          err => {
            console.log('Error detected:'+err.error_msg);
            this.setLoading(false);
          },
          () => {
            this.processMessages();
          }
        );

        // Send message history
        //this._data.endPoint(this.messages['_value'], 'message-history').subscribe (data => {}, err => {}, () => {});

        this.setProcessMessage(null)
    })

  }

  sendAddress(address) {
    //console.log('sendAddress fired')
    var link = JSON.parse(localStorage.getItem('talk_back_link'));
    var messages2 = [];

    address['messages'] = this.messages['_value'] // add Messages list to each message sent

    this._data.endPoint(address, link) // use for live
    //this._data.testOptions() // use for testing
      .subscribe(
        data => {
          // Set Global data
          messages2 = this.messages['_value'];
          localStorage.setItem('talk_back_link', JSON.stringify(data.talk_back_link));
          this.setLoading(false);

          this.chatMessagesHolder = data.chat_response; // set response to object
          this.setChatMessages(data.chat_response) // set response to object

          //update Messages to correct context
          this.setMessageData(this.chatMessagesHolder); // set response to service storage object
          if(this.route === '/chat' || this.route === '/'){
            this.setMessages(messages2); // set response to service storage object
          }
          if(this.route === '/help'){
            this.setHelpMessages(messages2); // set response to service storage object
          }
          if(this.route === '/edit'){
            this.setEditMessages(messages2); // set response to service storage object
          }

          // iterate over the response object to pick up messge types and instantiate chat bubbles
          // based on message type eg. message, input, button etc

        },
        err => {
          console.log('Error detected:'+err.error_msg);
          this.setLoading(false);
        },
        () => {
          this.processMessages();
        }
      );
  }

  sendDate(date) {
    //console.log('sendDate fired')
    var link = JSON.parse(localStorage.getItem('talk_back_link'));
    var messages2 = [];

    date['messages'] = this.messages['_value'] // add Messages list to each message sent

    this._data.endPoint(date, link) // use for live
    //this._data.testOptions() // use for testing
      .subscribe(
        data => {
          // Set Global data
          messages2 = this.messages['_value'];
          localStorage.setItem('talk_back_link', JSON.stringify(data.talk_back_link));
          this.setLoading(false);

          this.chatMessagesHolder = data.chat_response; // set response to object
          this.setChatMessages(data.chat_response) // set response to object

          //update Messages to correct context
          this.setMessageData(this.chatMessagesHolder); // set response to service storage object
          if(this.route === '/chat' || this.route === '/'){
            this.setMessages(messages2); // set response to service storage object
          }
          if(this.route === '/help'){
            this.setHelpMessages(messages2); // set response to service storage object
          }
          if(this.route === '/edit'){
            this.setEditMessages(messages2); // set response to service storage object
          }

          // iterate over the response object to pick up messge types and instantiate chat bubbles
          // based on message type eg. message, input, button etc

        },
        err => {
          console.log('Error detected:'+err.error_msg);
          this.setLoading(false);
        },
        () => {
          this.processMessages();
        }
      );
  }

  processMessages() {
    //console.log('processMessages fired')
    this.chatMessagesHolder.forEach(item => {
      var type = Object.keys(item); // grab the type of the message based on the key, set to "type"

      if (type.includes('message')) {
        this.setFormDetected(false);
        var i = item.message // declare .message case
        if(i.length > 0){
          this.setHasOptions(false);
          //console.log('messages are: '+JSON.stringify(i));
          i.forEach(element => {
            if(element.input_text != ''){
              //console.log('empty message was caught')
            }
             // if message is the first object, set isFirst to true
            let firstCheck: boolean;
            if (element === i[0]) {
              firstCheck = true;
            } else {
              firstCheck = false;
            }

            if (element.length != 0) {
              this.messagesHolder.push(
                new Message(element,'message','bot',firstCheck,'',new Date())
              );
            }
          });
          var messagesHolderObject1 = [];
          //this.messages['_value'].forEach(message => { messagesHolderObject1.push(message); });
          if(this.messages['_value']){this.messages['_value'].forEach(message => { messagesHolderObject1.push(message); });}
          this.messagesHolder.forEach(message => { messagesHolderObject1.push(message); });
          this.setMessages(messagesHolderObject1);
          this.messagesHolder = [];
        }
      };
      if (type.includes('processing')) {
        this.setFormDetected(false);
        var i = item.processing // declare .processing case
        if(i.length > 0) {
          i.forEach(element => {
            this.setProcessMessage(element);
          });
        }
      };
      if (type.includes('inputs')) {
        this.setFormDetected(false);
        var i = item.inputs // declare .inputs case
        if(i.length > 0) {
          this.setHasOptions(true);
          //console.log('inputs are: '+JSON.stringify(i));
          i.forEach(element => {
            this.messagesHolder.push(
              new MessageInput(element.type, element.question, element.value, element.answerOptions, element.date_time)
            );
          });
          var messagesHolderObject2 = [];
          this.messages['_value'].forEach(message => { messagesHolderObject2.push(message); });
          this.messagesHolder.forEach(message => { messagesHolderObject2.push(message); });
          this.setMessages(messagesHolderObject2);
          this.messagesHolder = [];
        } else if (i.length === 0) {
          //console.log('empty options detected')
          this.setHasOptions(false);
          //console.log(this.hasOptions['_value'])
        } else {
          this.setHasOptions(false);
        }
      };
      if (type.includes('form')) {
        this.setHasOptions(true);
        this.setProcessStatus(false);  // set processingStatus to false
        this.setFormDetected(true);
        //console.log('form; formDetected is: '+this.formDetectedHolder )

        localStorage.setItem('currentFormName', JSON.stringify(item.form[0].name)) // set current form name

        // extract and build an array for the forms fields
        if (item.form[0].sections) {
          // Form Sections detected
          var sections = Object.values(item.form[0].sections); // might use Object.keys to parse name of section

          // Populate Sections to Messages for use in othre components
          Object.keys(item.form[0].sections).forEach((key)=>{
            localStorage.setItem('repeatable', JSON.stringify(item.form[0].sections[key].repeatable)) // set current section repeatable
            this.setSectionArray(new FormSection(key, 'section','bot', item.form[0].sections[key].elements, new Date()));
            /* this.messagesHolder.push(
              new FormSection(key, 'section','bot', item.form[0].sections[key].elements)
            ); */

            var fields = Object.values(item.form[0].sections[key].elements)
            if (fields.length > 0) {
              //console.log('section fields detected: '+JSON.stringify(fields));
              fields.forEach(field => {
                field['sectionName'] = key; // append the name of the section to a field property
                field['name'] = key+'-'+field['name']; // append the name of the section to a field property
                if(field['type'] === 'text') {
                  this.setFieldArray(new TextboxQuestion (field));
                  this.messagesHolder.push(
                    new TextboxQuestion (field)
                  );
                }
                if(field['type'] === 'number') {
                  this.setFieldArray(new TextboxQuestion (field));
                  this.messagesHolder.push(
                    new TextboxQuestion (field)
                  );
                }
                if(field['type'] === 'dropdown') {
                  this.setFieldArray(new DropdownQuestion (field));
                  this.messagesHolder.push(
                    new DropdownQuestion (field)
                  );
                }
                if(field['type'] === 'checkbox') {
                  this.setFieldArray(new CheckboxQuestion (field));
                  this.messagesHolder.push(
                    new CheckboxQuestion (field)
                  );
                }
                if(field['type'] === 'textarea') {
                  this.setFieldArray(new TextareaQuestion (field));
                  this.messagesHolder.push(
                    new TextareaQuestion (field)
                  );
                }
              });
            }
          });
        } else {
          // No form Sections detected
          var fields = Object.values(item.form[0].elements)

          fields.forEach(field => {
            if(field['type'] === 'text') {
              this.setFieldArray(new TextboxQuestion (field));
              this.messagesHolder.push(
                new TextboxQuestion (field)
              );
            }
            if(field['type'] === 'number') {
              this.setFieldArray(new TextboxQuestion (field));
              this.messagesHolder.push(
                new TextboxQuestion (field)
              );
            }
            if(field['type'] === 'dropdown') {
              this.setFieldArray(new DropdownQuestion (field));
              this.messagesHolder.push(
                new DropdownQuestion (field)
              );
            }
            if(field['type'] === 'checkbox') {
              this.setFieldArray(new CheckboxQuestion (field));
              this.messagesHolder.push(
                new CheckboxQuestion (field)
              );
            }
            if(field['type'] === 'textarea') {
              this.setFieldArray(new TextareaQuestion (field));
              this.messagesHolder.push(
                new TextareaQuestion (field)
              );
            }
          });
        }

        // Add the form to Messages

        var i = item.form // declare .form case
        i.forEach(element => {
          this.messagesHolder.push(
            new Form(element.name, 'form', 'bot', this.fieldArrayHolder, this.sectionArrayHolder, new Date())
          );
        });

        // Collect and Update Messages array
        var messagesHolderObject = [];
        this.messages['_value'].forEach(message => { messagesHolderObject.push(message); });
        //this.setNewForm(this.messagesHolder);
        this.printForm(this.messagesHolder);
        this.messagesHolder.forEach(message => { messagesHolderObject.push(message); });
        this.setMessages(messagesHolderObject);
        this.messagesHolder = [];

        this.setFormDetected(true); // reset
      };
    });
  }

  printForm(fields) {
    //console.log('printForm called')

    this.setNewForm(fields)

    var newFormHolder;
    var fieldSet = [];
    this.newForm$.subscribe(data =>{newFormHolder = data}); // sub to locally actionable var

    newFormHolder.forEach(element => {
      if (element.questionShort) {
        fieldSet.push(element)
        //console.log('field detected' )
      }
      if (element.type === 'section') {
        fieldSet.push(element)
      }
      if (element.type === 'form') {
        fieldSet.push(element)
      }
    });

    this.setNewForm(fieldSet);
    this.setFormDetected(false); // reset form detection
  }

  // Sleep utility function to simulate delay for UX
  sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }


}
