import { Component, OnInit } from '@angular/core';
import {sequence, trigger, stagger, animate, style, group, query as q, transition, keyframes, animateChild} from '@angular/animations';
//const query = (s,a,o={optional:true})=>q(s,a,o);

import { DataService } from '../../_services/data.service';
import { MessageService } from '../../_services/message.service';
import { Message } from '../../_models/message-types/message';
import { MessageInput } from '../../_models/message-types/input';
import { config } from 'src/app/_client/config';

/* export const helpTransition = trigger('helpTransition', [
  transition(':enter', [
    query('.bg', animate('200ms', keyframes([
      style({ transform: 'scale(1,1)', opacity: 1  }),
      style({ transform: 'scale(90,90)', opacity: 1  }),
    ]))),
  ]),
  transition(':leave', [
    query('.bg', animate('200ms', keyframes([
      style({ transform: 'scale(90,90)', opacity: 1  }),
      style({ transform: 'scale(1,1)', opacity: 1  }),
    ]))), 
  ])
]); */

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  //animations: [ helpTransition ],
  host: {
    '[@helpTransition]': ''
  }
})

export class HelpComponent implements OnInit {

  config = config;

  public message : Message;
  public input: MessageInput;
  public messages : any;

  w_height = window.innerHeight;
  holderHeight = this.w_height * 0.8;
  currentScale = this.holderHeight * 0.5

  constructor(
    private _data: DataService,
    private _msg: MessageService,
  ) {
    // Create initial message and list objects, with structure
    _msg.helpMessages$.subscribe(data => this.messages = data) // get Messages data back via service
    this.messages = [];
    this.message = {'input_text':'', 'type':'message', 'author':'bot', 'isFirst':false, 'value':'value', 'date_time': new Date()};
    this.input = {'type': '', 'author':'bot', 'question': '', 'value': '', 'answerOptions': [], 'date_time': new Date()};
   }

  ngOnInit() {
    //console.log('currentScale is: '+this.currentScale)
    let bg:HTMLElement = document.getElementsByClassName("bg")[0] as HTMLElement;
    bg.style.transform = 'scale(' + this.currentScale +',' + this.currentScale +')';

    var helpInitMessage: any;
    this._msg.helpInitMessage$.subscribe(data => helpInitMessage = data)
    // if route is coming from /chat -> print generic message repeating helpInitMessage
    this.messages.push(
      new Message('This is help. you requested help with '+'"'+helpInitMessage+'"','messsage', 'bot',true)
    );
    // else -> let API communicate message

  }

  ngOnDestroy() {
    this.messages = []; // empty messages so that on init, new help context can be initialised
  }

}
