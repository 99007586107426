import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';

import { FieldBase } from '../_models/form-fields/field-base';

import { ValidateNumberRange } from '../_validators/numberRange.validator';

@Injectable({
  providedIn: 'root'
})
export class FormControlService {

  // Validation holder variables
  valRequired: any;
  valEmail: any;


  constructor() { }

  toFormGroup(fields: FieldBase<any>[] ) {
    let group: any = {};

    fields.forEach(field => {

      if(field.type != 'form') {
        // Specify validations based on "validations" array for each field from json
        if (field.validations) {
          let validatorsAll: any[] = []; // var to hold all validations
          var validations = Object.values(field.validations); // var to collect the validation flags to search
            
          // Validators.required
          if (validations.includes('required')){
            validatorsAll.push(Validators.required) // push the validation to master var
          };
  
          // Validators.email
          if (validations.includes('email')){
            validatorsAll.push(Validators.email)
          };
  
          // ValidateNumberRange
          if (validations.includes('numberRange')){
            validatorsAll.push(ValidateNumberRange(300, 3000))
          };
  
          // Change the field.name property to include the sectionName
          //field.name = field.sectionName+'-'+field.name
  
          // Attach validations to field
          group[field.name] = new FormControl(field.value || '', validatorsAll);
        } else {
          group[field.name] = new FormControl(field.value || '');
        }
      }


      
      //this version works - required needs to exist as boolean on main level of field
      /* group[field.name] = field.required ? new FormControl(field.value || '', Validators.required)
                                          : new FormControl(field.value || ''); */
                                          

    });
    return new FormGroup(group);
  };
  toFormArray(fields: FieldBase<any>[] ) {
    //let array: any = [];
    let array = new FormArray([])

    fields.forEach(field => {

      // Specify validations based on "validations" array for each field from json
      if (field.validations) {
        let validatorsAll: any[] = []; // var to hold all validations
        var validations = Object.values(field.validations); // var to collect the validation flags to search
          
        // Validators.required
        if (validations.includes('required')){
          validatorsAll.push(Validators.required) // push the validation to master var
        };

        // Validators.email
        if (validations.includes('email')){
          validatorsAll.push(Validators.email)
        };

        // ValidateNumberRange
        if (validations.includes('numberRange')){
          validatorsAll.push(ValidateNumberRange(300, 3000))
        };

        // Attach validations to field
        array.push(new FormGroup({ [field.name]: new FormControl(field.value || '', validatorsAll) }));
      } else {
        array.push(new FormGroup({ [field.name]: new FormControl(field.value || '') }));
      }

    });
    return array;
  }

}
