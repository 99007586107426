// This file is used to configure client specific settings throughout the app.

export const config = {
    apiUrl: 'http://commander.cavalryapps.com/ci-bot/ammo-zend/public/alpha',
    //apiUrl: 'http://9cb8d32c.ngrok.io/BOT_API_V2/bot-api/public/alpha',
    settings: {
        client: 'Cartrack | Bot', // client company name
        env: 'fullscreen', // options: modal, fullscreen, chat
        mode: 'quoteBind', // quote, faq, support
        logo: '', // location of logo
    },
    models: {
        product: 'car', // name this API uses to describe insurance product eg: bike, car, person, item 
    },
    maps: {
        settings: {
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v9',
            center: [27.9698123, -26.1713504],
            zoom: 10,
            interactive: false,
        },
        search: {
            limit: '10',
            country: 'za',
        }
    },
    datetime: {
        format: 'd/M/yy h:mm a',
        locale: 'en-ZA'
    },
    businessRules: {
        valMinMax: {
            min: 300,
            max: 3000
        },
        amountMinMax: {
            min: 300,
            max: 3000
        }
    }
}