import { Component, OnInit, Input } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { config } from '../../_client/config';

import { MapService } from 'src/app/_services/map.service';

@Component({
  selector: 'app-map',
  template: `
    <div id="map" class="map"></div>
  `,
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  config = config;

  @Input() map: mapboxgl.Map;
  markerCreate: boolean;
  coordinates: any[] = []

  constructor(
    private _map: MapService,
  ) {
    Object.getOwnPropertyDescriptor(mapboxgl, "accessToken").set('pk.eyJ1IjoiY2h1a3VyYSIsImEiOiJjanViZ2R4bmMwNWE3NGVwZGVvMHg0ZTFmIn0.vt7iWW3jBEhIIL9lqOUt3w');

    this._map.markerCreate$.subscribe(
      val => {
        this.markerCreate = val;
        //console.log('markerCreate: '+this.markerCreate)
        if (this.markerCreate === true) {
          this._map.marker$.subscribe(
            data => { 
              data.forEach(element => {
                this.coordinates.push(element)
              });
              this.createPin(); 
            }
          );
        }
      }
    );
   }

  ngOnInit() {
    this.map = new mapboxgl.Map({
      container: this.config.maps.settings.container,
      style: this.config.maps.settings.style,
      center: [this.config.maps.settings.center[0],this.config.maps.settings.center[1]],
      zoom: this.config.maps.settings.zoom,
      interactive: this.config.maps.settings.interactive,
    });
  }

  createPin() {
    //console.log('createPin fired')
    var marker = new mapboxgl.Marker()
  .setLngLat([this.coordinates[0], this.coordinates[1]])
  .addTo(this.map);

  this.map.flyTo({center: [this.coordinates[0], this.coordinates[1]], zoom: 14, speed: 3});
  }

}
