import { Component, OnInit } from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
//import { MapMouseEvent, Map, Marker, accessToken } from 'mapbox-gl';
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-test',
  template: `
    <div id="map2" class="map2"></div>
    <button (click)="createPin()">Create</button>
  `,
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {

  map: any;

  constructor() { 
    Object.getOwnPropertyDescriptor(mapboxgl, "accessToken").set('pk.eyJ1IjoiY2h1a3VyYSIsImEiOiJjanViZ2R4bmMwNWE3NGVwZGVvMHg0ZTFmIn0.vt7iWW3jBEhIIL9lqOUt3w');
  }

  ngOnInit() {
    this.map = new mapboxgl.Map({
      container: 'map2', // <- missing div!
      style: 'mapbox://styles/mapbox/light-v8',
      center: [-0.1277583, 51.5073509],
      zoom: 14,
    });
  }

  createPin() {
    var marker = new mapboxgl.Marker()
  .setLngLat([-0.1277583, 51.5073509])
  .addTo(this.map);

  this.map.flyTo({center: [-0.1277583, 51.5073509], zoom: 14});
  }

}
