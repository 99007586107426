import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Accept': 'application/json',
  }),
  withCredentials: false
};

const httpFormOptions = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
  }),
  withCredentials: false
};
const httpFormOptionsSearch = {
  headers: new HttpHeaders({
    'Accept': 'application/json, text/javascript, */*; q=0.01',
  }),
  withCredentials: false
};

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private messageData = new BehaviorSubject<any>(null); // store for Messages data
  public messageData$ = this.messageData.asObservable(); // observble store object
  
  private newMessage = new BehaviorSubject<any>(null); // store for Messages data
  public newMessage$ = this.newMessage.asObservable(); // observble store object
  
  // Form Variables
  private submitted = new BehaviorSubject<any>(null); // store for Messages data
  public submitted$ = this.submitted.asObservable(); // observble store object

  constructor(private http: HttpClient) { }

  // Set Actions for Data Stores
  // method takes Messages data generated from an API call elsewhere, then stores here for sharing in Observable
  setMessageData(data) {
    this.messageData.next(data) // sets data in private messageData object
  }
  setNewMessage(data) {
    this.newMessage.next(data) // sets data in private messageData object
  }
  setSubmitted(data) {
    this.submitted.next(data) // sets data in private messageData object
  }

  userMessage (data) {
    return this.http.post<any>(`${environment.apiUrl}/user-message`, data, httpOptions)
  }
  
  endPoint (data, talk_back_link) {
    return this.http.post<any>(`${environment.apiUrl}/${talk_back_link}`, data, httpFormOptions)
  }
  
  testMessage () {
    return this.http.get<any>('http://localhost:4200/assets/json/data.json?data=test', httpOptions)
  }
  testOptions () {
    return this.http.get<any>('http://localhost:4200/assets/json/map.json?data=test', httpOptions)
  }

  testMessageData () {
    return this.http.get('http://localhost:4200/assets/json/map.json?data=test', httpOptions) 
    .pipe (map((data) => {
      return data;
    })) // this map creates an observable that can set in store
  } 

  getAddress (data) {
    return this.http.post<any>(`${environment.apiUrl}/location-search`, data, httpFormOptionsSearch)
  }
}
