export class Form<T> {
    name: string;
    type: string;
    author: string;
    fields: Array<string>;
    sections: Array<string>;
    date_time: Date;

    constructor(
        name?: string,
        type?: string,
        author?: string,
        fields?: Array<string>,
        sections?: Array<string>,
        date_time?: Date,
    ) {
        this.name = name;
        this.type = 'form';
        this.author = 'bot';
        this.fields = fields;
        this.sections = sections;
        this.date_time = date_time;
    }
}