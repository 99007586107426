import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';

import { AuthInterceptor } from './_interceptors/auth.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatComponent } from './views/chat/chat.component';
import { HelpComponent } from './views/help/help.component';
import { EditComponent } from './views/edit/edit.component';
import { NavigationComponent } from './layouts/navigation/navigation.component';
import { TestComponent } from './views/test/test.component';
import { ChatListComponent } from './layouts/chat-list/chat-list.component';
import { ChatItemComponent } from './layouts/chat-item/chat-item.component';
import { ChatFormComponent } from './layouts/chat-form/chat-form.component';
import { DynamicFormComponent } from './templates/dynamic-form/dynamic-form.component';
import { DynamicFormFieldComponent } from './templates/dynamic-form-field/dynamic-form-field.component';
import { MapComponent } from './templates/map/map.component';
import { LivechatComponent } from './views/livechat/livechat.component';
import { LoaderComponent } from './layouts/loader/loader.component';

@NgModule({
  declarations: [
    AppComponent,
    ChatComponent,
    HelpComponent,
    EditComponent,
    NavigationComponent,
    TestComponent,
    ChatListComponent,
    ChatItemComponent,
    ChatFormComponent,
    DynamicFormComponent,
    DynamicFormFieldComponent,
    MapComponent,
    LivechatComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ChatFormComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
