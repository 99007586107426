import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { config } from 'src/app/_client/config';

import { DataService } from '../../_services/data.service';
import { MessageService } from '../../_services/message.service';
import { Message } from '../../_models/message-types/message';
import { MessageInput } from '../../_models/message-types/input';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  config = config;

  public message : Message;
  public input: MessageInput;
  public messages : any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _data: DataService,
    private _msg: MessageService,
  ) {
    // Create initial message and list objects, with structure
    this.messages = [];
    this.message = {'input_text':'', 'type':'message', 'author':'bot', 'isFirst':false, 'value':'value', 'date_time': new Date()};
    this.input = {'type': '', 'author':'bot', 'question': '', 'value': '', 'answerOptions': [], 'date_time': new Date()};
   }

  ngOnInit() {
  }

}
