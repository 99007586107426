import { Component, EventEmitter, Input } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { environment } from '../environments/environment';
import { routerTransition } from './router.animations';
import { Message } from './_models/message-types/message';
import { MessageInput } from './_models/message-types/input';
import { config } from './_client/config';

import { DataService } from './_services/data.service';
import { MessageService } from './_services/message.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ routerTransition ],
})
export class AppComponent {
  title = 'ng-quote-bot';
  config = config;
  ready: boolean = false;

  navigationSubscription;

  /* public message : Message;
  public input: MessageInput;
  public messages : any; */

  @Input('message')
  private message : Message;

  @Input('input')
  private input : MessageInput;

  @Input('messages')
  private messages : any;

  // Communication variables

  startMessage: any = environment.settings.startMessage; // set the message text to expected prompt
  formatMessage: any;

  returnData: any;
  talk_back_link: any;
  chatMessages: any;
  chatInputs: any;

  constructor(
    private _data: DataService,
    private _msg: MessageService,
    private titleService:Title,
    private route: ActivatedRoute,
    private router: Router,
    ) {
    titleService.setTitle(config.settings.client);
    // REMOVE AFTER SESSION HANDLIN IMPLEMENTED
    localStorage.removeItem('apikey');
    localStorage.removeItem('authkey');

    // These are needed to create initial message and list objects, with structure
    this.messages = [];

    // Get start message
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {

        // Check if URL based startMessage is in use
        let param = this.route.root.firstChild.paramMap['destination']['_value']['id'];
        if (param) {
          //console.log('root: '+this.route.root.firstChild.paramMap['destination']['_value']['id']);
          this.startMessage = param;
          // Send welcome message
          localStorage.setItem('talk_back_link', JSON.stringify('user-message'));
          this._msg.sendMessageOptions({'input_text':this.startMessage, 'type':'message', 'author':'user', 'value':this.startMessage}, this.messages)
          this._msg.setHasOptions(false);
        } else {
          if(e.url != '/livechat'){
            // Send welcome message
            localStorage.setItem('talk_back_link', JSON.stringify('user-message'));
            this._msg.sendMessageOptions({'input_text':this.startMessage, 'type':'message', 'author':'user', 'value':this.startMessage}, this.messages);
            this._msg.setHasOptions(false);
          }
        }
      }
    });


  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }

  ngAfterViewChecked() {
    this.iOsFontFix();
  }

  iOsFontFix() {
    let iOS: boolean;
    if(navigator.userAgent.includes('iPhone')){ iOS = true };
    if(navigator.userAgent.includes('iPad')){ iOS = true };
    if(navigator.userAgent.includes('iPod')){ iOS = true };
    if( iOS === true ) {

      let bodyy = document.getElementsByTagName('body');
      let inputy = document.getElementsByTagName('input');
      let textareay = document.getElementsByTagName('textarea');
      let selecty = document.getElementsByTagName('select');
      let paragraphs = document.getElementsByTagName('p');

      for (var i = 0; i<bodyy.length; i++) { bodyy[i].style.fontSize = '16px'; }
      for (var i = 0; i<inputy.length; i++) { inputy[i].style.fontSize = '16px'; }
      for (var i = 0; i<textareay.length; i++) { textareay[i].style.fontSize = '16px'; }
      for (var i = 0; i<selecty.length; i++) { selecty[i].style.fontSize = '16px'; }
      for (var i = 0; i<paragraphs.length; i++) { paragraphs[i].style.fontSize = '16px'; }

    }
  }

}
