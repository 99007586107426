import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Form } from '../../_models/message-types/form';
import { FormSection } from '../../_models/message-types/form-section';
import { FieldBase } from '../../_models/form-fields/field-base';
import { DataService } from '../../_services/data.service';
import { FormControlService } from '../../_services/form-control.service';
import { MessageService } from '../../_services/message.service';
import { config } from 'src/app/_client/config';

import { Message } from '../../_models/message-types/message';
import { MessageInput } from '../../_models/message-types/input';
 
declare var $:any;

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [ FormControlService ]
})
export class DynamicFormComponent implements OnInit {
  config = config;

  @Input('message')
  private message : Message;
  @Input('input')
  private input : MessageInput;
  @Input('messages')
  private messages : any;

  @Input() sections: FormSection<any>[] = [];
  @Input() fields: FieldBase<any>[] = [];
  forms: any = [];

  @Input() field: FieldBase<any>;
  @Input() section: FormSection<any>;
  @Input() form0: FormGroup;


  route: string; // var to capture location

  // Communication variables
  formatMessage: any;
  newMessage: any;
  serviceMessages: any;
  fieldArray = <any>[]
  sectionArray = <any>[]

  returnData: any;
  talk_back_link: any;
  chatMessages: any;

  // Loading, validation vars
  loading = false;
  submitted: any;
  submittedFinal = false;
  
  counter = 0; // used to iterate addModel field names

  formName: any;
  reformForm = false;
  repeatable: any;
  submittedDetails: any[] = [];

  constructor(
    private _form: FormControlService,
    private _data: DataService,
    private _msg: MessageService,
    private formBuilder: FormBuilder,
    private router: Router,
    private location: Location
    ) { 
      // use location to set route
      router.events.subscribe((val) => {
        if(location.path() != ''){
          this.route = location.path();
        } else {
          this.route = '/'
        }
      });
 
      this.route = this.router.url;  
      // Capture Messages from service
      if(this.route === '/chat' || this.route === '/'){

        this._msg.messages$.subscribe(
          msgs => {
            if (!this.messages) {
              //console.log('messages object invalid');
              this.messages = msgs;
              //console.log('Service messages invalid are: '+JSON.stringify(msgs));
            } else {
              //console.log('Service messages are: '+JSON.stringify(msgs));
              this.messages = msgs;
            }
          },
          err => {},
          () => {
          }
        );
      }
      if(this.route === '/help'){
        this._msg.helpMessages$.subscribe(
          msgs => {
            if (!this.messages) {
              this.messages = msgs;
            } else {
              this.messages = msgs;
            }
          },
          err => {},
          () => {
          }
        );
      }
      if(this.route === '/edit'){
        this._msg.editMessages$.subscribe(
          msgs => {
            if (!this.messages) {
              this.messages = msgs;
            } else {
              this.messages = msgs;
            }
          },
          err => {},
          () => {
          }
        );
      }
     }
 
  ngOnInit() {
    this.reformForm = true;
    this.repeatable = JSON.parse(localStorage.getItem('repeatable')); // get repeatable state from localStorage
    
    this.forms.push(this.fields);
    this.form0 = new FormGroup({});
    this.form0.addControl('model'+this.counter, this._form.toFormGroup(this.fields));

    //console.log(this.form0.root);
  }

  // convenience getter for easy access to form fields for validation
  get f() { return this.form0.controls; }
  

  onSubmit() {
    //console.log('form0 is: '+JSON.stringify(this.form0.value));
    this.submitted = true;
    this._data.setSubmitted(true);

    // stop here if form is invalid
    if (this.form0.invalid) {
      //console.log('errors detected: ');
      //console.log(this.form0.errors);
      // Scroll to first invalid and focus
      let target;
        target = $('input.ng-invalid').first();
        if (target) {
            $('html,body').animate({ scrollTop: $(target).offset().top - 110 }, 'slow', ()=> {
              target.focus();
            });
        }
      return;
    }

    this.loading = true;

    //console.log('form0 is: '+JSON.stringify(this.form0.value));

    // format message as formData
    var formData = new FormData();
    var formGroupIndex = 0;
    //Object.keys(this.form.value).forEach((key)=>{formData.append(key,this.form.value[key])});
    
    /* this.masterForm.controls['models'].value().forEach(e => {
      formData.append('models', e);
    }); */

    var currentFormName = JSON.parse(localStorage.getItem('currentFormName')); // get current form name from localStorage
    this.formName = currentFormName;
    var innerFormData = {
      'modelName': currentFormName,
      'name': currentFormName,
      "sections": []
    }

    Object.values(this.form0.value).forEach(value=>{
      innerFormData.sections.push(value)
    });

    Object.keys(innerFormData).forEach((key)=>{
      formData.append(key,JSON.stringify(innerFormData[key]))
    });

    var form_data = [];
    form_data.push(innerFormData)

    let modelObject1 = new FormData();

    Object.values(form_data).forEach(value => {
      modelObject1.append("form_data[]", JSON.stringify(value));
    });

    // If you want to manipulate the key names on the formData use:
    /* Object.keys(this.form.value).forEach((key)=>{
      var oldKey = key
      key = 'form_data['+ formGroupIndex +']['+ key +']';
      formData.append(key,this.form.value[oldKey])
    }); */

    this.talk_back_link = JSON.parse(localStorage.getItem('talk_back_link')); // get talk_back_link from storage

    this._msg.sendMessageOptions(modelObject1, this.messages)
    
    // Reset Form
    this._msg.setHasOptions(false);
    this.submittedFinal = true;
    this.resetForm();
  }

  addModel() {
    //console.log('Add a model');
    var fieldsHolder = [];

    // 1 - Create cloned object
    var newFields = this.fields.map(x => Object.assign({}, x)); // creates deep clone of this.fields
    
    // 2 - Append new index to field.name
    Object.values(newFields).forEach((field)=>{
      var oldKey = field['name'];
      //field['name'] = '['+this.counter+']'+oldKey; // change key using counter eg [1]BicycleYear
      fieldsHolder.push(field);
    });  

    // 3 - Add all fields into a new object
    var fields3 = [];
    this.fields.forEach(field=>{fields3.push(field)});
    fieldsHolder.forEach(field=>{fields3.push(field)});

    // 4 - use new object to create this.form and update FE
    var indexCtrl = this.counter + 1; // control to remove correct counter index
    //console.log('model'+indexCtrl)
    this.form0.addControl('model'+indexCtrl, this._form.toFormGroup(fieldsHolder)); // update NG form
    this.forms.push(newFields); // update FE
    
    //console.log('new form is: '+JSON.stringify(this.form0.value));
    //console.log(this.forms);
    //console.log(this.form0.root);
    
    this.counter = this.counter + 1; // 5 - increase counter for next run
    this.scrollToBottom();

  }

  removeModel(index) {
    //console.log('Remove a model of id: '+indexCtrl);
    //var indexCtrl = index - 1; // control to remove correct counter index

    this.form0.removeControl('model'+index.toString()); // remove from NG form
    this.forms.splice(index, 1); // remove from FE
    
    //console.log('new form is: '+JSON.stringify(this.form0.value));
    //console.log(this.forms);
    //console.log(this.form0.root);
    
    this.counter = this.counter - 1; // decrease counter for next run
  }

  scrollToBottom () {
    let target;
    target = $('.form-row').last();
    if (target) {
      $('html,body').animate({ scrollTop: $(target).offset().top - 110 }, 'slow', () => {
        target.focus();
        //console.log('scrolled')
      });
    }
  }

  resetForm() {
    //console.log('create clone')

    var modelsHolder = [];

    Object.values(this.form0.value).forEach(model=>{
      var thisModel = []
      Object.values(model).forEach(field => {
        thisModel.push(field); // push values to iterable
      })
      modelsHolder.push(thisModel)  // push values to iterable
    });

    this.submittedDetails.push(modelsHolder)
    
    //console.log('form reset')
    // Reset form0 for use next init
    this.reformForm = false;
    this.form0 = new FormGroup({});
    this.fields = [];
    this.forms = [];
  }

}
