import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChatComponent } from './views/chat/chat.component'
import { HelpComponent } from './views/help/help.component'
import { EditComponent } from './views/edit/edit.component'
import { LivechatComponent} from './views/livechat/livechat.component'
import { TestComponent } from './views/test/test.component'

const routes: Routes = [
  {
    path: '',
    component: ChatComponent
  },
  {
    path: 'chat',
    component: ChatComponent,
    data: {state: 'chat'}
  },
  {
    path: 'chat/:id',
    component: ChatComponent,
    runGuardsAndResolvers: 'paramsChange'
  },
  {
    path: 'help',
    component: HelpComponent,
    data: {state: 'help'}
  },
  {
    path: 'edit',
    component: EditComponent,
    data: {state: 'edit'}
  },
  {
    path: 'livechat',
    component: LivechatComponent,
    data: {state: 'livechat'}
  },
  {
    path: 'test',
    component: TestComponent,
    data: {state: 'test'}
  },
  // otherwise redirect to chat
  { path: '**', redirectTo: '/chat' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
