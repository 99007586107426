import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { config } from 'src/app/_client/config';

import { DataService } from '../../_services/data.service';
import { MessageService } from '../../_services/message.service';
import { Message } from '../../_models/message-types/message';
import { MessageInput } from '../../_models/message-types/input';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  config = config;

  public message : Message;
  public input: MessageInput;
  public messages : any;
  private ready : boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _data: DataService,
    private _msg: MessageService,
  ) {
    // Create initial message and list objects, with structure

    //Single Message Test
    /* this.messages = [
      new Message('Welcome to Sunday\'s Insurance')
    ]; */
    //Multi Message Test
    /* this.messages = [{"input_text":"Welcome to Sunday's Insurance","type":"message"}, {"input_text":"This is a stagger test","type":"message"}] */

    _msg.messages$.subscribe(data => {
      this.messages = data;
      if(data != null){
        this.ready = true;
      }
    })
     // get Messages data back via service
    //this.messages = [];
    this.message = {'input_text':'', 'type':'message', 'author':'bot', 'isFirst':false, 'value':'value', 'date_time': new Date()};
    this.input = {'type': '', 'author':'bot', 'question': '', 'value': '', 'answerOptions': [], 'date_time': new Date()};
   }

  ngOnInit() {
  }

}
