import { Component, OnInit, Input } from '@angular/core';
import { FormGroup }        from '@angular/forms';

import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Message } from '../../_models/message-types/message';
import { MessageInput } from '../../_models/message-types/input';
import { MessageProcessing } from '../../_models/message-types/processing';
import { FieldBase } from '../../_models/form-fields/field-base';

import { DataService } from '../../_services/data.service';
import { MessageService } from '../../_services/message.service';
import { config } from 'src/app/_client/config';

@Component({
  selector: 'chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.scss']
})
export class ChatFormComponent implements OnInit {
  config = config;

  @Input('message')
  private message : Message;

  @Input('input')
  private input : MessageInput;
  
  @Input('processing')
  private processing : MessageProcessing;

  @Input('messages')
  private messages : any;

  @Input('question') question: FieldBase<any>;
  @Input('form') form: FormGroup;

  route: string; // var to capture location

  // Communication variables

  formatMessage: any;
  newMessage: any;
  serviceMessages: any;
  fieldArray = <any>[]
  sectionArray = <any>[]

  returnData: any;
  talk_back_link: any;
  chatMessages: any;
  chatMessagesService: any;
  chatInputs: any;

  // Form variables
  loading = false;
  submitted = false;

  isFirst = false;

  hasOptions: boolean;

  constructor(
    private _data: DataService,
    private _msg: MessageService,
    private router: Router,
    private location: Location,
  ) { 
    // use location to set route
    router.events.subscribe((val) => {
      if(location.path() != ''){
        this.route = location.path();
      } else {
        this.route = '/'
      }
    });
      
    // Capture Messages from service
    if(this.route === '/chat' || this.route === '/'){
      this._msg.messages$.subscribe(
        msgs => {
          if (!this.messages) {
            //console.log('messages object invalid');
            this.messages = msgs;
            //console.log('Service messages invalid are: '+JSON.stringify(msgs));
          } else {
            //console.log('Service messages are: '+JSON.stringify(msgs));
            this.messages = msgs;
          }
        },
        err => {},
        () => {
        }
      );
    }
    if(this.route === '/help'){
      this._msg.helpMessages$.subscribe(
        msgs => {
          if (!this.messages) {
            this.messages = msgs;
          } else {
            this.messages = msgs;
          }
        },
        err => {},
        () => {
        }
      );
    }
    if(this.route === '/edit'){
      this._msg.editMessages$.subscribe(
        msgs => {
          if (!this.messages) {
            this.messages = msgs;
          } else {
            this.messages = msgs;
          }
        },
        err => {},
        () => {
        }
      );
    }

    // Capture option click and print new message
    this._msg.newMessage$.subscribe(
      msg => {
        //console.log('printed: '+JSON.stringify(msg));
        if (!this.message) {
        
        }
        else {
          this.messages.push(
            new Message(msg.input_text, msg.type, msg.author, msg.isFirst, msg.value, new Date())
          );
          // send message to API
          // using logic here to ensure if msg is a button ie. has value set, that the value is sent as input_text
          if(msg.value.length > 0) {
            msg.input_text = msg.value;
            msg.date_time = new Date();
            this.sendMessageOptions(msg);
          }else {
            msg.date_time = new Date();
            this.sendMessageOptions(msg);
          }
        }
      },
      err => {},
      () => {
      }
    );
    this._msg.chatMessages$.subscribe(data => {this.chatMessagesService = data});
    this._msg.hasOptions$.subscribe(data => {this.hasOptions = data});
  }

  ngOnInit() {
    this.elasticIn('');
  }

  ngAfterViewChecked() {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      if(w < 767) {
        // do nothing
      } else {
      var  inputField = document.getElementById("input");
      if (inputField) {
        inputField.focus();
      }
    }
  }

  elasticIn (data: any) {
    let send:HTMLElement = document.getElementsByClassName("send")[0] as HTMLElement;
    if (send) {
      if (data.length === 0) {
        send.classList.remove("elasticIn");
      } else {
        send.classList.add("elasticIn");
      }
    }
  }

  sendMessage() {
    // check if entry is empty, if so, do nothing
    if (this.message.input_text.length != 0) {
      //console.log('ping')
      this._msg.setNewMessage({'input_text':this.message.input_text, 'type':'message', 'author':'user', 'value':this.message.input_text, 'date_time': this.message.date_time});
      //this._msg.sendMessage(this.message, this.messages);

      this.message = {'input_text':'', 'type':'message', 'author':'user', 'isFirst':false, 'value':'value', 'date_time': new Date()}; // reset input text
    } else {
      // do nothing
    }

  }

  sendMessageOptions(data) {
    this._msg.sendMessageOptions(data, this.messages)
    this._msg.setHasOptions(false)
  }

}