import { FieldBase } from "../form-fields/field-base";

export class FormSection<T> {
    name: string;
    type: string;
    author: string;
    fields: Array<any>;
    date_time: Date;

    constructor(
        name?: string,
        type?: string,
        author?: string,
        fields?: Array<any>,
        date_time?: Date,
    ) {
        this.name = name;
        this.type = 'section';
        this.author = 'bot';
        this.fields = fields;
        this.date_time = date_time;
    }
}