import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import { MessageService } from 'src/app/_services/message.service';

declare var olark:any;

@Component({
  selector: 'app-livechat',
  templateUrl: './livechat.component.html',
  styleUrls: ['./livechat.component.scss']
})
export class LivechatComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('loading') loading: ElementRef;
  @ViewChild('chatbox') chatbox: ElementRef;

  chat_history_link: string;
  welcome_msg: string = "Hello. I'm here to help you with any questions your have. What can I help you with today?"

  constructor( private messageService: MessageService ) { }

  ngOnInit() { }

  ngAfterViewInit() {

    this.chat_history_link = this.messageService.chat_history_link;
    let scriptLoaded = this.loadOlarkScript();
    scriptLoaded.then(()=> {
      /* custom configuration goes here (www.olark.com/documentation) */ 
      olark.configure('box.inline', true);
      olark.configure('system.is_single_page_application', true );
      olark.configure('system.hb_hide_minimize_button', true);
      olark.configure('box.start_expanded', true);
      olark.identify('6559-894-10-3764');

      // Send a custom notification
      olark('api.chat.sendNotificationToOperator',
      {
        body: 'Chat History Link: ' + this.chat_history_link
      });
      //olark('api.chat.sendMessageToVisitor', {
      //  body: this.welcome_msg
      //});
      olark('api.chat.onReady', () => {
        this.loading.nativeElement.classList.add('chat-loaded');
        this.chatbox.nativeElement.classList.add('active');
      });

    });
    
  }

  loadOlarkScript() {
    return new Promise ((resolve, reject)=> {
      const body = <HTMLDivElement> document.body;
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = './assets/js/olark.js';
      script.async = true;
      body.appendChild(script);
      script.onload = () => {
        resolve();
      };
    });
  }

  ngOnDestroy(){
    this.loading.nativeElement.classList.remove('chat-loaded');
    this.chatbox.nativeElement.classList.remove('active'); 
  }

}
