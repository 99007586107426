import { Injectable, Input } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from '../_services/message.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    @Input('messages')
    private messages : any;
    startMessage: any = environment.settings.startMessage; // set the message text to expected prompt

    constructor(
        private router: Router,
        private _msg: MessageService,
     ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // add authorization header with securityToken to all http

        let apikey = localStorage.getItem('apikey');
        let authkey = localStorage.getItem('authkey');
        if (apikey && authkey) {

            request = request.clone({
                setHeaders: {
                    // 'Authorization': 'Bearer '+currentUser.securityToken,
                    //'Content-Type':  'application/json',
                    //'Accept': 'application/json',
                    'apikey': apikey,
                    'authkey': authkey
                }
            });
        }
        // console.log(request);

        return next.handle(request).pipe(
            tap(event => {
                if (event instanceof HttpResponse)
                {
                    // catch the rolling api key...
                    if (event.body.apikey && event.body.authkey)
                    {
                        localStorage.setItem('apikey', event.body.apikey);
                        localStorage.setItem('authkey', event.body.authkey);
                    }

                    // SIMULATING HELP FLAG FOR STATE SWITCHING
                    /* if (!event.body.chat_response.help) {
                        //console.log('help is false')
                        event.body.chat_response.help = true;
                        //console.log('help is now: '+event.body.help )
                    } */
                }
            }),
            catchError((error, caught) => {
                //intercept the respons error and displace it to the console
                //console.log(error);
                this.handleAuthError(error);
                //return of(error);
                throw error.error;
            }) as any );
    }

     private handleAuthError(err: HttpErrorResponse): Observable<any> {
        //handle your auth error or rethrow

        if (err.status === 401) {
            //navigate /delete cookies or whatever
            console.log('handled error ' + err.status);
            // restart chat
            this._msg.sendMessageOptions({'input_text':this.startMessage, 'type':'message', 'author':'user', 'value':this.startMessage}, this.messages);

            return of(err.message);
        }
        if (err.status === 500) {
            //navigate /delete cookies or whatever
            console.log('handled error ' + err.status);
            // print bot message
            /* this._msg.setNewMessage({'input_text':'Oops! Something\'s gone terribly wrong!', 'type':'message', 'author':'bot', 'value':'Oops! Something\'s gone terribly wrong!'}); */

            return of(err.message);
        }

        //throw error.error;
      }
}
