import { Component, OnInit, Input } from '@angular/core';
import { config } from '../../_client/config';
import { first } from 'rxjs/operators';
import { ChatFormComponent } from '../chat-form/chat-form.component';
import { MapMouseEvent, Map, Marker } from 'mapbox-gl';
import * as mapboxgl from 'mapbox-gl';

import { Message } from '../../_models/message-types/message';
import { MessageInput } from '../../_models/message-types/input';
import { MessageProcessing } from '../../_models/message-types/processing';
import { Form } from '../../_models/message-types/form';

import { MessageService } from '../../_services/message.service';
import { QuestionService } from '../../_services/question.service';
import { MapService } from 'src/app/_services/map.service';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/_services/data.service';
import { formatDate } from '@angular/common';

declare var $:any;

@Component({
  selector: 'chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.scss'],
  providers:  [QuestionService]
})
export class ChatItemComponent implements OnInit {
  config = config;

  @Input('messages')
  messages : any; // create a messages variable of type Messages (as per model)

  @Input('message')
  message: Message; // create a messages variable of type Messages (as per model)
  
  @Input('input')
  input: MessageInput;

  @Input('processing')
  processing : MessageProcessing;

  /* @Input('form')
  private form: Form; */
  
  newMessage: any;

  sections: any[] =[];
  fields: any[] =[];
  forms: any[] =[];
  holder: Array<any> = [];

  // Processing
  bikecoverProcess = false;
  
  // MapBox Map
  
  @Input('map')
  private map: mapboxgl.Map;

  // Searchable

  searchForm: FormGroup;
  selectedAddress: any;
  addresses: any;

  query: any;
  showResults = false;
  loading = false;
  doneSearch = false;
  formDetected = false;
  newForm: any;
  formSub: Subscription;

  // Datepicker

  dateForm: FormGroup;
  selectedDate: any;
  doneDate = false;
  public minDate = Date.now();

  constructor(
    private _msg: MessageService,
    _fields: QuestionService,
    private _data: DataService,
    private _map: MapService,
    private formBuilder: FormBuilder) { 

      this._msg.formDetected$.subscribe(val => { this.formDetected = val});
      this._msg.newForm$.subscribe(form => { if(form){form.forEach(field => { this.fields.push(field) })};});
    }

  ngOnInit() {
    // declare searchable form here
    this.searchForm = this.formBuilder.group({
      searchable: [''],
    });
    // declare datepicker form here
    this.dateForm = this.formBuilder.group({
      datetime: [''],
    });
  }

  ngAfterViewChecked() {
    this.elasticIn();
    this.hideInput();
  }

  elasticIn() {
    var el = document.getElementsByClassName('anim');
    for (var i = 0; i < el.length; i++) {
        var currentEl = el[i];
        currentEl.classList.add("elasticIn");
    }
  }

  sendMessageOption(data, data2) {
    this._msg.setHasOptions(false);
    this._msg.setNewMessage({'input_text':data, 'type':'btn-message', 'author':'user', 'value':data2, 'date_time': new Date()});
  }

  setDate() {
    let format = this.config.datetime.format;
    let locale = this.config.datetime.locale;
    let formattedDate = formatDate(this.selectedDate, format, locale)
    this._msg.sendDate({'input_text':formattedDate, 'type':'message', 'author':'user', 'value':formattedDate, 'date_time': new Date()})

    this._msg.setHasOptions(false)
    this.doneDate = true;
  }

  search(event) {
    this.query = event.target['value'];
    this.showResults = true;
    this.loading = true;

    if (this.query.length > 2) {
      var formData = new FormData();
      formData.append('search_term', this.query)
      formData.append('limit', this.config.maps.search.limit)
      formData.append('country', this.config.maps.search.country)

      this._data.getAddress(formData).subscribe(
        data => {
        this.addresses = data.data['addresses']
        //console.log('addresses are is: '+JSON.stringify(this.addresses))
        this.scrollToBottom();
        },
        err => {
          console.log('error')
        },
        () => {}
      );
      this.loading = false;
    }
  }

  addressSelect (fullAddress, lat, lng, address) {
    this.showResults = false;
    this.doneSearch = true;
    this.searchForm.get('searchable').disable();
    this.selectedAddress = fullAddress;
    //console.log('selected selectedAddress is: '+this.selectedAddress);
    localStorage.setItem('selectedAddress', JSON.stringify(address));

    // 1 - run geocode
    this._map.setMarker([lng, lat]);
    this._map.setMarkerCreate(true);

    // 2 - send message
    var formData = new FormData();
    Object.keys(address).forEach((key)=>{formData.append(key,address[key])});

    this._msg.sendAddress(formData)

  }

  searchResetOnBackspace (event) {
    if (event.keyCode === 8 && this.selectedAddress) { // if you hit backspace and have already selected a result, reset
        console.log('cleansed!');
        this.selectedAddress = event.target['value'];
        this.search(event); // repopulate results
    }
  };

  scrollToBottom () {
    let target;
    target = $('.search-result').last();
    if (target) {
      $('html,body').animate({ scrollTop: $(target).offset().top - 110 }, 'slow', () => {
        target.focus();
        //console.log('scrolled')
      });
    }
  }

  // Only function that controls chat input visibility
  hideInput() {
    if(this.message.type === 'searchable') {
      this._msg.setHasOptions(true);
      var searchable = document.getElementById("searchable");
      if (searchable) {
        searchable.focus();
      }
    }
    if(this.message.type === 'form') {
      this._msg.setHasOptions(true);
    }
    else if (this.message.type === 'message') {
      this._msg.setHasOptions(false);
    } else {
      //this._msg.setHasOptions(true);
    }
  };

}
