import { Component, OnInit, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

import { config } from 'src/app/_client/config';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnChanges {
  config = config;
  chat_type: string = 'chatbot';
  dropdown_text: string = 'More options';
  showClose = false;

  @ViewChild('dropdown_nav') dropdown_nav : ElementRef;
  @ViewChild('dropdown_menu') dropdown_menu : ElementRef;

  constructor( private router: Router ) { }

  ngOnInit() {
    // listen to page variable from router events
    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        let route = event.state.root.firstChild;
        if(route.data.state == 'livechat'){
          this.chat_type = 'livechat'
        }
        else{
          this.chat_type = 'chatbot';
        }
      }
    });
  }

  ngOnChanges() {

  }

  toggleDropdown() {
    this.dropdown_nav.nativeElement.classList.toggle("active");
    this.dropdown_menu.nativeElement.classList.toggle("active");
    if(this.dropdown_menu.nativeElement.classList.contains('active')){
      //this.dropdown_text = 'Close';
      this.showClose = true;
    }
    else{
      //this.dropdown_text = 'More options';
      this.showClose = false;
    }
  }

  backClicked() {
    //change header type, will need additional logic to close live chat
    this.chat_type = 'chatbot';

    //use this to refresh the app
    localStorage.clear();
    window.location.href = '/chat';

    //below has been replaced with the code above, to force the app to reload
    //this.router.navigateByUrl('/');
  }

}
